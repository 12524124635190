<template>
<v-card style="padding: 30px" class="mx-auto elevation-5" color="white darken-2" dark max-width="600">
    <template v-if="ticket.contest_id">
        <!-- TICKET INFO -->
        <div style="display: flex; flex-direction: column; align-items: center; margin-bottom: 20px; border-bottom: dashed black; border-top: dashed black;">
            <span class="text-h7 black--text">BANCA ÚNICA</span>
        </div>

        <div style="display: flex; flex-direction: column; border-bottom: dashed black 2px; padding-bottom: 10px; margin-bottom: 10px;">
            <span class="text-h7 black--text">PONTO: {{ticket.point_name}}</span>
            <span class="text-h7 black--text">BILHETE: {{ticket.id}}</span>
            <span class="text-h7 black--text">CÓDIGO: {{ticket.code}}</span>
            <span class="text-h7 black--text">EMISSÃO: {{ formatDateFull(ticket.created_at) }}</span>
            <span class="text-h7 black--text">Concurso: {{ ticket.contest_name }}</span>
        </div>

        <!-- TICKET GAMES -->
        <div v-if="this.$store.getters.getLogged && this.loggedUser().type != 'cambista'">
            <div style="display: flex; flex-direction: column; border-bottom: dashed black 2px; padding-bottom: 10px;">
                <div v-if="ticket.paid == true" style="display: flex; justify-content: space-between; align-items: center; margin-top: 5px;">
                    <i class="fas fa-check-circle" style="color: lime; font-size: 30px"></i>
                </div>
                <span class="text-h7 black--text" > Modalidade: {{ ticket.modality }}</span>
                <span class="text-h7 black--text" > Palpites: {{ JSON.parse(ticket.selected_numbers).join(', ') + ' (' + JSON.parse(ticket.selected_numbers).length + ')' }}</span>
                <span class="text-h7 black--text" > Sub-total: {{ ticket.total_value }}</span>
            </div>
        </div>

        <div v-else>
            <div style="display: flex; flex-direction: column; border-bottom: dashed black 2px; padding-bottom: 10px;">
                <span class="text-h7 black--text" > Modalidade: {{ ticket.modality }}</span>
                <span class="text-h7 black--text" > Palpites: {{ JSON.parse(ticket.selected_numbers).join(', ') + ' (' + JSON.parse(ticket.selected_numbers).length + ')' }}</span>
                <span class="text-h7 black--text" > Sub-total: {{ ticket.total_value }}</span>
            </div>
        </div>


        <div>
            <span class="text-h7 black--text">Total: R$ {{ticket.total_value}}</span>
        </div>                    
    </template>

    <template v-if="ticket.round_id">
        <!-- TICKET INFO -->
        <div style="display: flex; flex-direction: column; align-items: center; margin-bottom: 20px; border-bottom: dashed black; border-top: dashed black;">
            <span class="text-h7 black--text">BANCA ÚNICA</span>
        </div>

        <div style="display: flex; flex-direction: column; border-bottom: dashed black 2px; padding-bottom: 10px; margin-bottom: 10px;">
            <span class="text-h7 black--text">PONTO: {{ticket.point_name}}</span>
            <span class="text-h7 black--text">BILHETE: {{ticket.id}}</span>
            <span class="text-h7 black--text">CÓDIGO: {{ticket.code}}</span>
            <span class="text-h7 black--text">EMISSÃO: {{ formatDateFull(ticket.created_at) }}</span>
            <span class="text-h7 black--text">Rodada: {{ ticket.number }}</span>
        </div>

        <!-- TICKET GAMES -->
        <div v-if="this.$store.getters.getLogged && this.loggedUser().type != 'cambista'">
            <div style="display: flex; flex-direction: column; border-bottom: dashed black 2px; padding-bottom: 10px;">
                <div v-if="ticket.paid == true" style="display: flex; justify-content: space-between; align-items: center; margin-top: 5px;">
                    <i class="fas fa-check-circle" style="color: lime; font-size: 30px"></i>
                </div>
                <span class="text-h7 black--text" > Palpites: {{ JSON.parse(ticket.selected_numbers).join(', ') + ' (' + JSON.parse(ticket.selected_numbers).length + ')' }}</span>
                <span class="text-h7 black--text" > Sub-total: {{ ticket.total_value }}</span>
            </div>
        </div>

        <div v-else>
            <div style="display: flex; flex-direction: column; border-bottom: dashed black 2px; padding-bottom: 10px;">
                <span class="text-h7 black--text" > Palpites: {{ JSON.parse(ticket.selected_numbers).join(', ') + ' (' + JSON.parse(ticket.selected_numbers).length + ')' }}</span>
                <span class="text-h7 black--text" > Sub-total: {{ ticket.total_value }}</span>
            </div>
        </div>

        <div style="display: flex; flex-direction: column; border-bottom: dashed black 2px; padding-bottom: 10px; margin-bottom: 10px;">
            <span v-for="(extraction, index) of ticket.extractions" :key="index" class="text-h7 black--text">{{ extraction.name + ' (' + formatDate(extraction.datetime) + ')' }}</span>
        </div>


        <div>
            <span class="text-h7 black--text">Total: R$ {{ticket.total_value}}</span>
        </div>                    
    </template>

    <template v-if="ticket.extraction_id">
        <!-- TICKET INFO -->
        <div style="display: flex; flex-direction: column; align-items: center; margin-bottom: 20px; border-bottom: dashed black; border-top: dashed black;">
            <span class="text-h7 black--text">BANCA ÚNICA</span>
        </div>

        <div style="display: flex; flex-direction: column; border-bottom: dashed black 2px; padding-bottom: 10px; margin-bottom: 10px;">
            <span class="text-h7 black--text">PONTO: {{ticket.point_name}}</span>
            <span class="text-h7 black--text">BILHETE: {{ticket.id}}</span>
            <span class="text-h7 black--text">CÓDIGO: {{ticket.code}}</span>
            <span class="text-h7 black--text">EMISSÃO: {{ formatDateFull(ticket.created_at) }}</span>
            <span class="text-h7 black--text">SORTEIO: {{ formatDateFull(ticket.extraction_date)}}</span>
        </div>

        <!-- TICKET GAMES -->
        <div v-if="this.$store.getters.getLogged && this.loggedUser().type != 'cambista'">
            <div style="display: flex; flex-direction: column; border-bottom: dashed black 2px; padding-bottom: 10px;" v-for="(item, index) of ticket.games" :key="index">
                <div v-if="item.status == 'win'" style="display: flex; justify-content: space-between; align-items: center; margin-top: 5px;">
                    <span class="text-h7 black--text" :class="{'font-weight-medium' : item.status == 'win'}"> Modalidade: {{ item.modality_name }}</span>
                    <i class="fas fa-check-circle" style="color: lime; font-size: 30px"></i>
                </div>
                <span v-else class="text-h7 black--text">Modalidade: {{ item.modality_name }}</span>
                <span class="text-h7 black--text" :class="{'font-weight-medium' : item.status == 'win'}"> Prêmios: {{ item.award_name }}</span>
                <span class="text-h7 black--text" :class="{'font-weight-medium' : item.status == 'win'}"> Palpites: {{ JSON.parse(item.selected_numbers).join(', ') + ' (' + JSON.parse(item.selected_numbers).length + ')' }}</span>
                <span class="text-h7 black--text" :class="{'font-weight-medium' : item.status == 'win'}"> Lado: {{ JSON.parse(item.selected_positions).join(', ') }}</span>
                <span class="text-h7 black--text" :class="{'font-weight-medium' : item.status == 'win'}"> Sub-total: {{ item.value }}</span>
            </div>
        </div>

        <div v-else>
            <div style="display: flex; flex-direction: column; border-bottom: dashed black 2px; padding-bottom: 10px;" v-for="(item, index) of ticket.games" :key="index">
                <div v-if="ticket.paid == 1 && item.status == 'win'" style="display: flex; justify-content: space-between; align-items: center; margin-top: 5px;">
                    <span class="text-h7 black--text" :class="{'font-weight-medium' : ticket.paid == 1 && item.status == 'win'}"> Modalidade: {{ item.modality_name }}</span>
                    <i class="fas fa-check-circle" style="color: lime; font-size: 30px"></i>
                </div>
                <span v-else class="text-h7 black--text">Modalidade: {{ item.modality_name }}</span>
                <span class="text-h7 black--text" :class="{'font-weight-medium' : ticket.paid == 1 && item.status == 'win'}"> Prêmios: {{ item.award_name }}</span>
                <span class="text-h7 black--text" :class="{'font-weight-medium' : ticket.paid == 1 && item.status == 'win'}"> Palpites: {{ JSON.parse(item.selected_numbers).join(', ') + ' (' + JSON.parse(item.selected_numbers).length + ')' }}</span>
                <span class="text-h7 black--text" :class="{'font-weight-medium' : item.status == 'win'}"> Lado: {{ JSON.parse(item.selected_positions).join(', ') }}</span>
                <span class="text-h7 black--text" :class="{'font-weight-medium' : ticket.paid == 1 && item.status == 'win'}"> Sub-total: {{ item.value }}</span>
            </div>
        </div>

        <div>
            <span class="text-h7 black--text">Total: R$ {{ticket.total_value}}</span>
        </div>                    
    </template>
</v-card>
</template>

<script>
import moment from 'moment';

export default {
    name: "BaseTicketOnline",

    props: {
        ticket: Object,
        type: String,
    },

    data: () => ({
    }),

    methods: {

        formatDate(date) {
            return moment(date).format('DD/MM/YYYY HH:mm');
        },


        // STATUS FORMAT
        formatStatus(value) {
            switch (value) {
                case 'open':
                    return 'Aberto';

                case 'canceled':
                    return 'Cancelado';

                case 'returned':
                    return 'Devolvido';

                case 'win':
                    return 'Ganhou';

                case 'lose':
                    return 'Perdeu';

                default:
                    break;
            }
        },

    }
}
</script>

<style>

.bet-lose {
    display: none;
}

.bet-win {
    display: block !important;
    font-size: 50px;
}

.icon-bet-win {
    display: flex;
}

.bet-win i {
    color: lime;
    font-size: 20px;
}

</style>