import { getUnixTime, addDays, format } from "date-fns";
import store from "../store/store";
import jsPDF from 'jspdf';
import 'jspdf-autotable'
window.Pusher = require('pusher-js');

import Echo from 'laravel-echo'
import moment from 'moment';

export default {
    methods: {
        // VERIFY IF TOKEN IS EXPIRED
        verifyExpirate() {
            // GET INFO FROM LOCALSTORAGE
            var expire = localStorage.getItem('expire');

            // COMPARE WITH THE ACTUAL UNIX TIME
            if (expire < getUnixTime(new Date)) {
                return false;
            } else {
                return true;
            }
        },

        Echo () {
            return window.Echo = new Echo({
                broadcaster: 'pusher',
                key: 'buKey',
                wsHost: process.env.VUE_APP_WS_URL,
                wsPort: 6001,
                wssPort: 6001,
                disableStats: true,
                enabledTransports: ['ws', 'wss'],
                encrypted: false,
                forceTLS: false,
            });
        },

        exportExcel(filename = this.$store.getters.getNavigation) {

            var downloadLink;
            var dataType = 'application/vnd.ms-excel';
            var tableSelect = document.querySelector('table');
            var tableHTML = tableSelect.outerHTML.replace(/ /g, '%20');

            filename = filename?filename+'.xls':'excel_data.xls';
            downloadLink = document.createElement('a');
            document.body.appendChild(downloadLink);

            if(navigator.msSaveOrOpenBlob) {
                var blob = new Blob(['\ufeff', tableHTML], {
                    type: dataType
                });
                navigator.msSaveOrOpenBlob(blob, filename);
            } else {
                downloadLink.href = 'data:' + dataType + ', ' + tableHTML;
                downloadLink.download = filename;
                downloadLink.click();
            }

        },

        //as
        printTable(data = [], headers = []) { 
            if(data.length ==  0 || headers.length == 0)  {
                window.print()
                return
            }

            // reorganizando ordem dos campos para ficar igual nos headers
            var data = data.map(function(item) { 
                var newItem = {};
                for (var i = 0; i < headers.length; i++) {
                  newItem[headers[i].field] = item[headers[i].field];
                }
                return newItem;
            });
            
            // Criando tabela com os dados
            var table = document.createElement('table');
            table.style.width = '100%';
            table.style.backgroundColor = 'transparent';
            table.style.borderCollapse = 'collapse';
            table.style.fontFamily = 'Arial';
                      
            var thead = document.createElement('thead');
            var tbody = document.createElement('tbody');
            var headRow = document.createElement('tr');

            for (var i = 0; i < Object.keys(data[0]).length; i++) {
                var key = Object.keys(data[0])[i];
                var found = headers.find(item => item.field == key);
               
                if (found) {
                    var th = document.createElement('th');
                    var text = document.createTextNode(found.header);
                    th.appendChild(text);
                    th.style.border= '1px solid #dee2e6'
                    th.style.backgroundColor = '#0871cd'
                    th.style.color = 'white'
                    headRow.appendChild(th);
                }
            }
          
            thead.appendChild(headRow);
            table.appendChild(thead);
          
            for (var i = 0; i < data.length; i++) {
                var obj = data[i];
                var tr = document.createElement('tr');

                for (var j = 0; j < Object.keys(obj).length; j++) {
                    var key = Object.keys(obj)[j];
                    var found = headers.find(item => item.field == key);

                    if (found) {
                        var value = obj[key]

                        if (!isNaN(value) && !Number.isInteger(value)) { // se for número decimal formata para moeda
                            value = value >= 0 ? this.formatCurrency(value) : '-' + this.formatCurrency(value)
                        } 

                        /* if(typeof value == 'string' && !isNaN(new Date(value).getTime())) { // se for data formata para data
                            value = moment(value).format('DD/MM/YY')
                        } */
                            
                        var td = document.createElement('td');
                        var text = document.createTextNode(value == undefined ? '' : value);
                        td.appendChild(text);
                        td.style.border = '1px solid #dee2e6';
                        td.style.textAlign = 'center';
                        tr.appendChild(td);
                    }
                }

                tbody.appendChild(tr);
            }
          
            table.appendChild(tbody);

            // cria uma nova aba e popula o html com a tabela criada
            var newWin = window.open('', 'Print-Window');
			newWin.document.write(table.outerHTML);
            newWin.document.write(
            ` <style>
                @media print {
                    @page {
                        size: landscape;
                    }
                }
            </style> 
            `
           )
			newWin.document.close();
			newWin.print();
        },

        generatePDF(title = 'Relatório', period = '') {
            // Captura a tabela
            var table = document.querySelector('table');
        
            // Cria o documento pdf
            var doc = new jsPDF('l', 'pt', 'a4');
        
            // Define a data da impressão
            var date = new Date();
        
            // Adiciona o número da página em cada uma delas
            doc.autoTable({
                html: table,
                addPageContent: function(data) {
                    doc.setFontSize(20)
                    doc.text(`${title} - ${period}`, data.settings.margin.left, 30);
                    doc.setFontSize(14)
                    doc.text("Impresso em: " + date.toLocaleString('pt-BR'), data.settings.margin.left, 570);
                    doc.text("Página " + data.pageNumber + " de " + data.pageCount, data.settings.margin.left + 680, 570);
                }
            });
        
            // Salva o pdf
            doc.save(`${title} - ${period}.pdf`);
        },

        isDecimal(value) {
            if (isNaN(value)) { // Verifica se o valor não é um número
              if (typeof value === 'string' && value !== '') { // Verifica se é uma string não vazia
                const numberValue = parseFloat(value);
                return !isNaN(numberValue) && isFinite(numberValue); // Verifica se o valor convertido para número é finito
              } else {
                return false; // Se não for número e nem string, retorna falso
              }
            } else {
              return Number.isFinite(value); // Se for número, verifica se é finito
            }
        },

        // DO LOGOUT 
        logOut() {
            // REMOVE EVERYTHING FROM LOCALSTORAGE
            localStorage.removeItem('expire');
            localStorage.removeItem('user');
            localStorage.removeItem('token');
            localStorage.isLogged = false;

            // REMOVE EVERYTHING FROM VUEX
            store.dispatch('setLoggout', {
                user: {},
                token: "",
                isLogged: false,
                expire: null
            });
        },
        
        // SHOW SNACKBAR NOTIFICATION
        snackbarShow(snackbar) {
            store.dispatch("setSnackbar", {
                snackbar: snackbar
            });
        },

        // FORMAT DATE 
        formatDate(date) {
            if (!date) return null;

            let new_date = addDays(new Date(date), 1);
            let edit_date = format(new_date, 'dd/MM/yyyy');
            return edit_date;
        },

        formatDateFull(date) {
            if (!date) return null;

            let new_date = addDays(new Date(date), 0);
            let edit_date = format(new_date, 'dd/MM/yyyy HH:mm');
            return edit_date;
        },

        formatTime(time) {
            if (!time) return null;
            
            let new_time = new Date(time);
            let edit_time = new_time.toLocaleTimeString([], {hour: '2-digit', minute: '2-digit'});
            return edit_time;
        },

        // MONEY FORMAT
        formatCurrency(value) {
            value = Math.abs(value);
            var val = parseFloat(value)
                .toFixed(2)
                .split(".");
            val[0] = val[0].split(/(?=(?:...)*$)/).join(".");
            return val.join(",");
        },

        // PORCENTAGE FORMAT
        formatPorcentage(value) {
            value = Math.abs(value);
            return value + '%';
        },

        // RETURN TOKEN
        accessToken() {
            let token = store.getters.getToken;
            return token;
        },

        // RETURN USER LOGGED
        loggedUser() {
            let user = store.getters.getUser;
            return user;
        },
        
        processAwardName(award_name){
            let name = ''

            switch (award_name) {
                case 'primeiro':
                    name = '1º'
                    break;
                case 'segundo':
                    name = '2º'
                    break;
                case 'terceiro':
                    name = '3º'
                    break;
                case 'quarto':
                    name = '4º'
                    break;
                case 'quinto':
                    name = '5º'
                    break;
                case 'sexto':
                    name = '6º'
                    break;
                case 'setimo':
                    name = '7º'
                    break;
                case 'primeiro_ao_terceiro':
                    name = '1º ao 3º'
                    break;
                case 'primeiro_ao_quinto':
                    name = '1º ao 5º'
                    break;
                case 'primeiro_ao_setimo':
                    name = '1º ao 7º'
                    break;
                case 'primeiro_e_segundo':
                    name = '1º e 2º'
                    break;
                case 'sexto_e_setimo':
                    name = '6º e 7º'
                    break;
                default:
                    name = award_name
                    break;
            }

            return name;
        },

        processPositionName(position){

            switch (position) {
                case 'Esquerda':
                    return  'E'

                case 'Direita':
                    return  'D'

                case 'Meio':
                    return  'M'
                default:
                    return ''
            }

            return name;
        },
    },
}